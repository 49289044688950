import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Form, { Field } from '@atlaskit/form';
import { RadioSelect } from '@atlaskit/select';
import Button from "@atlaskit/button";
import TextField from "@atlaskit/textfield";
import TextArea from "@atlaskit/textarea";
import axios from 'axios';

import { AppContext } from '../../store/index';
import utils from '../../utils';
import { hostingTypes } from '../../constants';
import './form.scss'

const InputForm = (props) => {
    const [ selectedHostingType, setHostingType ] = useState('');
    const [ state, dispatch ] = useContext(AppContext);
    const [ emailError, setEmailError ] = useState(false);
    const [ domainError, setDomainError ] = useState(false);
    const [ domainNameError, setDomainNameError ] = useState(false);
    const history = useHistory();
    const [ comparableResources, setComparableResources ] = useState(null);
    const [ selectedResources, setSelectedResources ] = useState({ all: false });


    useEffect(() => {
        axios.get("/v1/jira/comparables").then((res) => {
            setComparableResources(res.data);
            const resourceKeys = {};
            for (let key in res.data) {
                resourceKeys[key] = false;
            }
            setSelectedResources({
                ...selectedResources,
                ...resourceKeys
            });
        });
        // eslint-disable-next-line   
    }, []);

    const handleOptionChange =(event)=>{
        setHostingType(event.value)
        dispatch({
            type: "UPDATE_TARGET_HOSTING_TYPE",
            payload: event.value
        })
        window.sessionStorage.setItem("hostingType", event.value);
    }
    window.sessionStorage.setItem('hosting-type', selectedHostingType);

    const validateUrl = () =>{
        let res = state.domain.match(/\b((http|https):\/\/?)[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|\/?))/g);
        if(res === null){
            return false;
        }
        else{
            console.log(res)
            return true;

        }

    }

    const validateEmail =()=>{
        const re = /^(([^<>()\],;:\s@]+([^<>()\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;
        let value = re.test(String(state.username).toLowerCase());

        if(value === true){
            setEmailError(false);

        }else{
            setEmailError(true);
        }
    }

    const validateDomainName = () =>{
        if (validateUrl() === false) {
            setDomainNameError(false);
        }else{
            setDomainNameError(true);
            return true;
        }
    }

    const validateDomain = () =>{
        let re = /\b((http|https):\/\/?)[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|\/?))/g;
        if(re.test(String(state.domain).toLowerCase())){
            setDomainError(false);
            return false;
        }else{
            setDomainError(true);
            return true;
        }
    }

    const formRequest = () => {
        if (selectedHostingType === "data-center") {
            setHostingType("server");
        }

        const requests = Object.keys(comparableResources).map(resource => {
            const path = comparableResources[resource].url
            // if resource is selected and resource is allowed for the selected hosting type
            if (selectedResources[resource] && comparableResources[resource][selectedHostingType]) {
                return axios.post(path, { "token": state.jwt, "hosting_type": selectedHostingType, "username": state.username, "password": state.password, "domain": state.domain })
                .then(res => {
                    const resourceData = res.data;
                    console.log(res.request)
                    // resourceData["hosting_type"] = res.request
                    return {
                        resource: resource,
                        response: resourceData
                    }
                }).catch(err => console.log(err));
            }
            return null;
        });

        Promise.all(requests).then(results => {
            const compareResults = {};

            results.forEach(result => {
                if (result === null || result === undefined) {
                    return
                }
                compareResults[result.resource] = result.response;
            });

            window.sessionStorage.setItem("compareResults", JSON.stringify(compareResults));
            dispatch({
                type: "UPDATE_COMPARE_RESULTS",
                payload: compareResults
            });
        })
        history.push('/resources');
    }

    const toggleAllResourceSelections = (bool) => {
        const resources = { all: bool }
        for (let key in selectedResources) {
            resources[key] = bool
        }
        setSelectedResources(resources)
    }

    const toggleResourceButton = (e) => {
        e.preventDefault();
        const key = e.target.value
        if (key === "all") {
            if (!selectedResources.all) {
                toggleAllResourceSelections(true);
            } else {
                toggleAllResourceSelections(false);
            }
        } else {
            if (selectedResources.all) {
                setSelectedResources({
                    ...selectedResources,
                    all: false,
                    [key]: !selectedResources[key]
                })
            } else {
                const resourceCopy = { ...selectedResources, [key]: !selectedResources[key] }
                if (utils.all(resourceCopy, ["all"])) {
                    setSelectedResources({
                        ...selectedResources,
                        all: true,
                        [key]: !selectedResources[key]
                    });
                } else {
                    setSelectedResources({
                        ...selectedResources,
                        [key]: !selectedResources[key]
                    });
                }
            }
        }
    }

    useEffect(() => console.log(selectedResources), [selectedResources]);

    const selectedResourcesDisplay = () => {
        const selected = [];
        for (let key in comparableResources) {
            if (selectedResources[key]) {
                selected.push(utils.titleCase(key))
            }
        }
        return selected.join(", ");
    }

    const resourceButtons = () => {
        const resources = []
        if (comparableResources) {
            for (let key in comparableResources) {
                if (selectedHostingType) {
                    if (comparableResources[key][selectedHostingType]) {
                        resources.push(
                            <button key={key}
                                    className={ `comparable-resources-option ${ selectedResources[key] ? 'clicked': '' }` }
                                    onClick={ toggleResourceButton }
                                    value={ key }
                                    >{ utils.titleCase(key) }</button>
                        );
                    }
                }
                else {
                    resources.push(
                        <button key={key}
                                className={ `comparable-resources-option ${ selectedResources[key] ? 'clicked': '' }` }
                                onClick={ toggleResourceButton }
                                value={ key }
                                >{ utils.titleCase(key) }</button>
                    );
                }

            }
            resources.push(
                <button key="all"
                        className={ `comparable-resources-option ${ selectedResources.all ? 'clicked': '' }` }
                        onClick={ toggleResourceButton }
                        value="all"
                        >All</button>
            )
        }
        return resources;
    }


    return(
        <div className="input-form">
            <Form onSubmit={ formRequest }>
                {({ formProps }) => (
                <form {...formProps}>
                    <h4 className="hosting-type-title">Choose Hosting Type</h4>
                    <RadioSelect
                        className="radio-select"
                        classNamePrefix="react-select"
                        options={ hostingTypes }
                        placeholder="Hosting Choice"
                        onChange={ handleOptionChange }
                    />
                    <Field name="domain"
                        defaultValue=""
                        label={ selectedHostingType !== 'cloud' ? "Domain" : "Domain Name" }
                        isRequired>
                        {({ fieldProps }) => <TextField onChangeCapture={ (e)=>{
                            dispatch({
                                type:'UPDATE_DOMAIN',
                                payload:e.target.value
                            })
                        } }
                        onBlurCapture={ selectedHostingType !== 'cloud' ? validateDomain : validateDomainName }
                        {...fieldProps} />}
                    </Field>
                    <p className={domainError === false ? 'none' : 'error'}>
                        <span class="icon" role="img" aria-label="Error">&#x274C;</span>
                        Incorrect Domain
                    </p>
                    <p className={domainNameError === false ? 'none' : 'error'}>
                        <span class="icon" role="img" aria-label="Error">&#x274C;</span>
                        Incorrect Domain name
                    </p>
                    <Field name="username"
                        defaultValue=""
                        label={ selectedHostingType !== 'cloud' ? "Username" : "Email" }
                        isRequired>

                        {({ fieldProps }) => <TextField onChangeCapture={ (e)=> {dispatch({
                            type: 'UPDATE_USERNAME',
                            payload: e.target.value
                        })} }
                        onBlurCapture={ selectedHostingType !== 'cloud' ? null : validateEmail }

                        {...fieldProps} />}

                    </Field>
                    <p className={emailError === false ? 'none' : 'error'}>
                        <span class="icon" role="img" aria-label="Error">&#x274C;</span>
                        Incorrect email
                    </p>
                    <Field name="password"
                        defaultValue=""
                        label={ selectedHostingType !== 'cloud' ? "Password" : "API Key" }
                        isRequired>
                        {({ fieldProps }) => <TextField type="password" onChangeCapture={ (e)=>{
                            dispatch({
                                type:'UPDATE_PASSWORD',
                                payload: e.target.value
                            })
                        }}
                        {...fieldProps} />}
                    </Field>

                    <div className="comparable-resources">
                        <h4>Comparable Resources</h4>
                        <TextArea value={ selectedResourcesDisplay() } name="text" isDisabled />
                        <div className="comparable-resources-options">
                            { resourceButtons() }
                        </div>
                    </div>
                    <Button type="submit" appearance="primary">
                        Compare
                    </Button>
                </form>
                )}
            </Form>
        </div>
    )
}

export default InputForm;
