import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../../store/index'

import './notFound.scss';

const NotFound = () =>{
    const [ state, dispatch ] = useContext(AppContext);

    dispatch({ type:null, payload:null})
    return(
        <div className="not-found">
            <img src={ require('../../assests/error.svg') } alt="Error"/>
            <p className='not-found-text'>
                Resource not found/requested, Go to
                <Link to={ `/home?jwt=${state.jwt}` }>
                     Homepage
                </Link>
            </p>
        </div>
    )
}

export default NotFound;