import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom";
import HomePage from './pages/homepage/homepage.js';
import OutputPage from './pages/output-page/output-page';
import InputForm from './components/form/form';
// import LoadingScreen from './components/loader/loader';
import ContextProvider from './store/index';
import './App.css';

const App = () => {
  return (
    <ContextProvider>
      <Router>
        <Route exact path="/">
          <Redirect to="/home"></Redirect>
        </Route>
        <Route exact path="/home" component={HomePage}/>
        <Route exact path="/resources" component={ OutputPage } />
        {/* <Route exact path="/loading" component={ LoadingScreen }/> */}
        <Route exact path="/test" component={ InputForm }/>
        {/* <Route exact path='/resources' component={ SingleResource }/> */}
      </Router>
    </ContextProvider>
  )
}

export default App;
