import React, { useState } from 'react';

import { head } from '../../constants';
import DynamicTable from '@atlaskit/dynamic-table';

import utils from '../../utils';
import './tabularOutputs.scss';

const TabularOutput = (props) => {

    const [ mainBody, setMainBody ] = useState(false);
    const [ targetBody, setTargetBody ] = useState(false);
    const [ matchedBody, setMatchedBody ] = useState(false);
    const [ unmatchedBody, setUnmatchedBody ] = useState(false);


  const handleMainBody = () =>{
      setMainBody((mainBody)=>!mainBody);
    }

    const handleTargetBody = () =>{
        setTargetBody((targetBody)=>!targetBody);
    }

    const handleMatchedBody = () =>{
        setMatchedBody((matchedBody)=>!matchedBody);
    }

    const handleUnmatchedBody = () =>{
      setUnmatchedBody((unmatchedBody)=>!unmatchedBody);
    }

    const getTargetName = () => {
        if (props.targetHostingType === "server" || props.targetHostingType === "data-center") {
            return "Target Instance";
        }
        return props.compareData.instances.target;
    }

    return(
        <div className="project-roles">
            <div className="project-roles output">
            <header>
                <h1> { utils.titleCase(props.resource) } Comparison</h1>
            </header>
            <div className="body">
                <div className="individual-resources">
                    <div className="resource-main">
                        <div className="resource-main-header" onClick={ handleMainBody }>
                            <img src={ require("../../assests/Dropdown.svg") } className={ mainBody ? 'dropdown': '' } alt="dropdown"/>
                            <p> <span className="main-name">{ props.compareData.instances.main }</span>  resources <span>({props.compareData.main.length})</span></p>
                        </div>
                        <div className={`resource-main-body ${mainBody ? 'open': '' }`}>
                            <div className="output-table">
                            <DynamicTable
                                head={ head }
                                rows={ utils.getTableRows(props.compareData, "main") }
                                defaultPage={ 1 }
                                loadingSpinnerSize="large"
                                defaultSortKey="name"
                                defaultSortOrder="ASC"
                            />
                            </div>
                        </div>
                    </div>
                        <div className="resource-target">
                            <div className="resource-target-header" onClick={ handleTargetBody }>
                                <img src={ require("../../assests/Dropdown.svg") } className={ targetBody ? 'dropdown': '' } alt="dropdown"/>
                                <p> <span className="target-name">{ getTargetName() }</span> resources <span>({props.compareData.target.length})</span></p>
                            </div>
                            <div className={ `resource-target-body ${ targetBody ? 'open': '' }` }>
                                <div className="output-table">
                                    <DynamicTable
                                        head={ head }
                                        rows={ utils.getTableRows(props.compareData, "target") }
                                        defaultPage={ 1 }
                                        loadingSpinnerSize="large"
                                        defaultSortKey="name"
                                        defaultSortOrder="ASC"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="comparison">
                        <div className="resource-matched">
                            <div className="resource-matched-header" onClick={ handleMatchedBody }>
                                <img src={ require("../../assests/Dropdown.svg") } className={ matchedBody ? 'dropdown': '' } alt="dropdown"/>
                                <p>Resources found on both Jira Instances <span>({props.compareData.matched.length})</span></p>
                            </div>
                            <div className={ `resource-matched-body ${matchedBody ? 'open': ''}` }>
                                <div className="output-table">
                                <DynamicTable
                                    head={ head }
                                    rows={ utils.getTableRows(props.compareData, "matched") }
                                    defaultPage={ 1 }
                                    loadingSpinnerSize="large"
                                    defaultSortKey="name"
                                    defaultSortOrder="ASC"
                                />
                                </div>
                            </div>
                        </div>
                        <div className="resource-unmatched">
                            <div className="resource-unmatched-header" onClick={ handleUnmatchedBody }>
                                <img src={ require("../../assests/Dropdown.svg") } className={ unmatchedBody ? 'dropdown': '' } alt="dropdown"/>
                                <p>Resources found on one Jira Instance but not on the other <span>({props.compareData.unmatched.length})</span> </p>
                            </div>
                            <div className={ `resource-unmatched-body ${unmatchedBody ? 'open': '' }` }>
                            <div className="output-table">
                                <DynamicTable
                                    head={ head }
                                    rows={ utils.getTableRows(props.compareData, "unmatched") }
                                    defaultPage={ 1 }
                                    loadingSpinnerSize="large"
                                    defaultSortKey="name"
                                    defaultSortOrder="ASC"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TabularOutput;