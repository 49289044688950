import React, { useContext, useEffect, useState } from 'react';
// import { useLocation } from "react-router-dom";

import InputForm from '../../components/form/form';
import { AppContext } from '../../store';

import './homepage.scss';

const HomePage = () => {
    const [ jwt, setJwt ] = useState("");
    const [ state, dispatch ] = useContext(AppContext);

    useEffect(() => {
      const location = window.location;
      const searchObj = new URLSearchParams(location.search.substr(1));
      const jwt = searchObj.get("jwt");
      setJwt(jwt);
    }, []);

    useEffect(() => {
        window.sessionStorage.setItem("jwt", jwt);
        dispatch({
            type: 'UPDATE_JWT',
            payload: jwt
        })
    }, [ dispatch, jwt ])

    return (
        <div className="cjr">
          <header>
            <img src="https://res.cloudinary.com/ecx/image/upload/v1603469872/Alluvium/CJR%20Assets/cjr_logo_mnk5vy.svg" alt="CJR Logo"/>
          </header>
          <main>
            <section className="input">
              <InputForm jwt={ state.jwt } />
            </section>
            <section className="info">
              <img src={ require('../../assests/opening.svg')} alt= 'opening quote'/>
              <div className="info-text">
               <h3>Compare Jira Resources (CJR)</h3> 
                Validate success of Migration by Comparing Source vs Target for Accuracy.

                <h4>What CJR Does:</h4>

                <p><h4>Validate Success of your Migration:</h4> Optimize your migration, by Validating that all data has been successfully migrated.</p>
                <p><h4>Compare Source / Target Before Consolidation:</h4> Allow Accuracy and Error free comparison of your data while on the source platform , transferring to the target platform.</p>
                <p><h4>Check Two Jira Instances' Delta:</h4> Check that test and sandbox instances are similar to Production - Compare Jira instances in real time using our compare tool.</p> 
              </div>
              <img src={ require('../../assests/closing.svg')} alt= 'closing quote'/>
            </section>
          </main>
        </div>
      );

}

export default HomePage;
