

// Changes string case to titlecase
const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}


// Iterates over an object and returns true if all its values are "truthy", else returns false.
// keys can be excluded from the check
const all = (obj, exclude) => {
  if (exclude === undefined) {
    exclude = []
  }
  return Object.keys(obj).reduce((prev, curr) => {
    if (exclude.indexOf(curr) >= 0) {
      return prev && true;
    }
    return prev && obj[curr];
  }, true)
}

const getTableRows = (data, key) => {
  if (data) {
      return data[key].map(resource => {
          return {
            cells: [
              {
                  key: resource.name,
                  content: resource.name,
              },
              {
                  key: resource.id,
                  content: resource.id,
              },
            ]
          }
      });
  }
}

export const sortedObjKeys = (obj) => {
  return Object.keys(obj).sort((a, b) => {
    if (a < b) {
      return -1;
    }
    else if (a > b) {
      return 1;
    }
    return 0;
  });
}

export default { titleCase, all, getTableRows, sortedObjKeys };
