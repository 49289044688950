import  React, { createContext, useReducer } from 'react';

export const AppContext = createContext();

const ContextProvider = ({ children }) =>{
    const initialState = {
        jwt: '',
        targetHostingType: '',
        domain: '',
        username: '',
        password:'',
        compareResults: {},
    };

    const reducer = (state, action) =>{
        switch (action.type) {
            case 'UPDATE_JWT':
               return {
                   ...state,
                    jwt: action.payload
               }

            case 'UPDATE_TARGET_HOSTING_TYPE':
                return {
                    ...state,
                    targetHostingType: action.payload
                }

            case 'UPDATE_DOMAIN':
                return {
                    ...state,
                    domain: action.payload
                }
            
            case 'UPDATE_HOSTING_OPTION':
                return{
                    ...state,
                    hosting_type:action.payload
                }

            case 'UPDATE_USERNAME':
                return {
                    ...state,
                    username: action.payload
                }

            case 'UPDATE_PASSWORD':
                return {
                    ...state,
                    password: action.payload
                }

            case 'UPDATE_COMPARE_RESULTS':
                return {
                    ...state,
                    compareResults: action.payload
                }
            default:
                return state;
        }
    }
    const [ state, dispatch ] = useReducer(reducer, initialState);


    return(
        <AppContext.Provider value={[state, dispatch]}>
            { children }
        </AppContext.Provider>
    )
}

export default ContextProvider;
