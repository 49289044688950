import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../store/index';

import TabularOutput from '../../components/tabularOutput/tabularOutput';
import NotFound from '../../components/notFound/notFound';

import utils from '../../utils';
import { svgIcons, svgPaths } from '../../constants';

import './output-page.scss';


const OutputPage = (props) => {
    const [ activeResource, setActiveResource ] = useState('');
    const [ menuState, setMenuState ] = useState(false);
    const [ loadingTrigger, setLoadingTrigger ] = useState(0);
    const [ state, dispatch ] = useContext(AppContext);
    const data = window.sessionStorage.getItem("compareResults");
    const dataValues = JSON.parse(data);

    useEffect(() => {
        const jwt = window.sessionStorage.getItem("jwt") || "";
        const hostingType = window.sessionStorage.getItem("hostingType") || "";
        let compareResults = {};
        if (data) {
            compareResults = JSON.parse(data);
        }
        dispatch({
            type: "UPDATE_COMPARE_RESULTS",
            payload: compareResults
        });
        dispatch({
            type: "UPDATE_JWT",
            payload: jwt
        });
        dispatch({
            type: "UPDATE_TARGET_HOSTING_TYPE",
            payload: hostingType
        })
    }, [dispatch,data]);

    useEffect(() => {
        const sortedKeys = utils.sortedObjKeys(state.compareResults);
        const active = sortedKeys[0] || "";
        setActiveResource(active);
    }, [state]);

    const topMenu = () => {
        const topMenuListItems = [];

        const sortedKeys = utils.sortedObjKeys(state.compareResults);
        for (let i = 0; i < (4 < sortedKeys.length ? 4 : sortedKeys.length); i++) {
            const resource = sortedKeys[i]
            topMenuListItems.push(
                <li key={ resource } className={ `top-menu-icon ${activeResource === resource ? 'active-option-icon' : '' }` }  onClick={ ()=> setActiveResource(resource) }>
                    <div className="resource-icon">
                        { svgIcons[resource] }
                    </div>
                    <p className="menu-text">{ utils.titleCase(resource) }</p>
                </li>
            );
        }

        return topMenuListItems;
    };

    const listMenu = () => {
        const listMenuListItems = utils.sortedObjKeys(state.compareResults).map(resource => {
            return (
                <li key={ resource } className={ activeResource === resource ? 'active-option' : '' }  onClick={ ()=> setActiveResource(resource) } >
                    <img src={ svgPaths[resource] } alt={ resource } />
                    <span>{ utils.titleCase(resource) }</span>
                    {console.log(resource)}
                </li>
                
            )
        });
        return listMenuListItems;
    };

    const menuIcon = () => {
        if (activeResource !== "") {
            return (
                <div className="menu-btn" onClick={ ()=>setMenuState(!menuState)}>
                    <img src="https://res.cloudinary.com/ecx/image/upload/v1603469871/Alluvium/CJR%20Assets/options_ih2uxr.svg" alt='Options' />
                    <p className="menu-text">Open all</p>
                </div>
            )
        }
    }

    setTimeout( ()=>{
        setLoadingTrigger(Object.keys(dataValues).length);
    },3000 )

    return(
        <div className="output-page">
            {
                loadingTrigger === 0
                ?
                    <div className="loading">
                        <div class="loading-shape"><i></i><i></i></div>
                        <p className="loading-text">Gathering resources</p>
                    </div>
                :
                <div>
                    <nav className="navigation">
                        <div className="logo">
                            <img src="https://res.cloudinary.com/ecx/image/upload/v1603469872/Alluvium/CJR%20Assets/cjr_logo_mnk5vy.svg" alt="CJR Logo"/>
                        </div>
                        <div className="options">
                            <Link className="home-link" to={`/home?jwt=${state.jwt}`}>
                                <img src="https://res.cloudinary.com/ecx/image/upload/v1603469872/Alluvium/CJR%20Assets/home_b6alpb.svg" alt="home button"/>
                                <span>Home</span>
                            </Link>
                            <div className="top-menu">
                                <ul>
                                    { topMenu() }
                                </ul>
                                { menuIcon() }
                            </div>
                        </div>
                        <div className={`menu ${menuState === true ? 'show-menu': ''}`}>
                            <ul>
                                { listMenu() }
                            </ul>
                        </div>
                    </nav>
                    <div className="main-output">

                        <div className={ activeResource === "" ? "" : `resource-body ${ Boolean(activeResource) ? 'active' : ''}` }>
                            {
                                activeResource !== "" ? <TabularOutput resource={ activeResource } compareData={ state.compareResults[activeResource] } targetHostingType={ state.targetHostingType } /> : <NotFound/>
                            }
                        </div>
                    </div>
                </div>

            }
            
        </div>
    )
}

export default OutputPage;